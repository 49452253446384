import axios from "axios";

// 解析 M3U8 檔案
export function parseM3U8(url) {
  return axios.get(url).then(response => {
    const m3u8Content = response.data;
    const baseURL = url.replace("manifest.m3u8", "")

    // 檢查是否有其他的 M3U8 檔案
    const nestedM3U8Urls = extractNestedM3U8Urls(m3u8Content);

    if (nestedM3U8Urls.length > 0) {
      // 如果有其他的 M3U8 檔案，遞迴解析它們(取其中一個播放清單即可)
      return parseM3U8(baseURL + nestedM3U8Urls[0])
    } else {
      // 如果沒有其他的 M3U8 檔案，直接提取影片長度
      const duration = extractDuration(m3u8Content);
      console.log('影片長度：', duration);
      return duration
    }
  }).catch(error => {
    console.error('無法獲取 M3U8 檔案：', error);
  });
}

// 提取嵌套的 M3U8 檔案的 URL
export function extractNestedM3U8Urls(m3u8Content) {
  const nestedM3U8Regex = /([^"]+\.m3u8)/g;
  const nestedUrls = [];
  let match;

  while ((match = nestedM3U8Regex.exec(m3u8Content)) !== null) {
    const nestedUrl = match[1];
    nestedUrls.push(nestedUrl.replace(/\n/g, ""));
  }

  return nestedUrls;
}

// 提取影片長度
export function extractDuration(m3u8Content) {
  // 解析 M3U8 檔案內容，獲取影片長度
  // 這裡假設片段的標籤是 "#EXTINF:<duration>" 的形式，其中 <duration> 是時間長度（以秒為單位）
  const durationRegex = /#EXTINF:([\d.]+)/g;
  const durations = m3u8Content.match(durationRegex);

  // 計算所有片段時間長度的總和，即為影片的時間長度
  let totalDuration = 0;
  durations.forEach(duration => {
    const durationValue = parseFloat(duration.substr(8));
    totalDuration += durationValue;
  });

  return totalDuration;
}
