<template>
  <CModal id="VideoModel" size="xl" :centered="true" :show.sync="VideoStoreConfig.Display">
    <CForm>
      <CCard>
        <CCardBody class="p-0">
          <CDataTable
              id="MediaVideoStore"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #Cover="{item}">
              <td>
                <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge v-if="!StatusLoading" :color="(item.Status === 'available' ? 'success' : 'danger')">
                  {{ $t('Video/Storage.StatusType.' + item.Status) }}
                </CBadge>
                <i v-else class="fas fa-spinner fa-pulse" />
              </td>
            </template>
            <template #Action="{index}">
              <td>
                <CButton color="dark" size="sm" class="mr-1" @click="ChooseSingleVideo(index)">
                  <CIcon name="cil-check" class="c-icon-custom-size mr-1"/> {{ $t('Video/Detail.ChooseVideo') }}
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="Init()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <template #footer-wrapper>
      <div />
    </template>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Media.Title') }}</h5>
        <CButtonClose @click="Toggle(false)" />
      </header>
    </template>
  </CModal>
</template>

<script>
import dayjs from "dayjs";
import { parseM3U8 } from '@/plugins/m3u8'

export default {
  name: 'MediaVideoStore',
  props: ['Config'],
  data () {
    return {
      List: [],
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      VideoStoreConfig: {
        Display: false,
        ChooseVideo: {},
      },
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Video/Storage.NoResults'),
        noItems: this.$t('Video/Storage.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Cover', label: this.$t('Video/Storage.Cover') },
        { key: 'Name', label: this.$t('Video/Storage.Name') },
        // { key: 'URI', label: this.$t('Video/Storage.URI') },
        { key: 'CreateTime', label: this.$t('Video/Storage.CreateTime') },
        { key: 'Status', label: this.$t('Video/Storage.Status') },
        { key: 'Action', label: '' }
      ]
    }
  },
  mounted() {
    this.ComponentID = this._uid
    this.VideoStoreConfig = this.Config
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetStoreList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetStoreList() {
      switch (this.$store.state.project.StreamingProvider) {
        case 'Vimeo':
          return this.GetVimeoList()
        default:
          return this.GetStorageList()
      }
    },
    GetVimeoList() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Loading = true
      const params = {
        Page: this.Pagination.Page || 1
      }
      if (PermissionSession.VimeoFolder) {
        params.folderId = PermissionSession.VimeoFolder
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/media/vimeo/store',
        method: 'get',
        params
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetStorageList() {
      let RequestQuery = {
        Page: this.Pagination.Page
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/video/storage/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.Data
        this.Pagination = data.Pagination
        this.StatusLoading = true
        setTimeout(() => {
          const Executes = data.Data.map((item) => {
            return this.$axios.get(`https://${this.$store.state.project.StreamBucket}/${item.Slug}/manifest.m3u8`)
          })
          return Promise.allSettled(Executes).then((result) => {
            result.forEach((data, index) => {
              if (data.status !== 'fulfilled' && (dayjs().unix() - dayjs(this.List[index].CreateTime).unix()) > 3600) {
                this.$set(this.List[index], 'Status', 'unavailable')
              }
              if (data.status === 'fulfilled') {
                this.$set(this.List[index], 'Status', 'available')
              }
              this.$set(this.List[index], 'EnableStream', data.status === 'fulfilled')
            })
            this.StatusLoading = false
          })
        }, 2000)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Toggle(status = true) {
      return new Promise((resolve) => {
        (status) ? this.$emit('Opening') : this.$emit('Closing')
        this.VideoStoreConfig.Display = status
        resolve()
      })
    },
    async ChooseSingleVideo(Index) {
      this.VideoStoreConfig.ChooseVideo = this.List[Index]
      this.VideoStoreConfig.ChooseVideo.Duration = await parseM3U8(`https://${this.$store.state.project.StreamBucket}/${this.List[Index].Slug}/manifest.m3u8`)
      this.$emit('OK', this.List[Index])
      this.Toggle(false)
    }
  }
}
</script>
